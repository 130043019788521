import React from "react"
import { SEO } from "../components"
import { graphql, useStaticQuery } from "gatsby"
import { Box } from "../styles"
import Layout from "../layouts"
import styled from "styled-components"
import {PressReleaseTable} from "../components/Table/press-release"
import {PressReleaseTableMobile} from "../components/Table/press-release-mob"
import { useWindowSize } from "../hooks"

const StyledPubHeader = styled.h2`
  z-index: 1;
  color: white;
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-style: italic;
  margin-left: 10%;
  margin-top: 200px;

  @media screen and (max-width: 1000px) {
    text-align: center;
    margin-left: 0%;
    margin-top: 15vh;
    font-size: 1.7rem;
  }
`

const TableContainer = styled.div`
  display: grid;
  align-content: start;
  justify-content: center;
  grid-template-columns: 1fr;

  align-items: center;
  justify-items: stretch;
  z-index: 2;

  margin: 200px;
  min-height: 600px;
  margin-top: 100px;
  grid-column-gap: 20px;
  margin-bottom: 200px;

  @media screen and (max-width: 1000px) {
    margin: 40px;
    margin-bottom: 200px;
  }
`

const TableTitle = styled.h3`
  font-size: 2rem;
  align-self: flex-end;
  font-style: italic;
  color: white;
  letter-spacing: 0.8rem;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px var(--primary), -1px -1px 1px var(--primary),
    1px -1px 1px var(--primary), -1px 1px 1px var(--primary);

  @media screen and (max-width: 1000px) {
    font-size: 1.4rem;
  }
`

const Publications = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { type: { regex: "/PR/" } } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              report
              location
              author
              date
              type
              key
              download
              slug
            }
            id
          }
        }
      }
    }
  `)

  const { width } = useWindowSize()

const PrData = data.allMarkdownRemark.edges.map((edge)=>edge.node.frontmatter)



  return (
    <>
      <SEO title="Press-Releases" />
      <Layout>
        <Box />
        <StyledPubHeader>Press Releases</StyledPubHeader>
        <TableContainer>
          {width > 1200 ? (
            <PressReleaseTable heading={"Report"} data={PrData} />
          ) : (
            <PressReleaseTableMobile heading={"Report"} data={PrData} />
          )} 
        </TableContainer>
      </Layout>
    </>
  )
}

export default Publications