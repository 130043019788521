import React from "react"
import styled from "styled-components"

const MobileTableContainer = styled.div`
  background: white;
  padding: 20px;
  border: 4px solid var(--secondcolor);
  margin-bottom: 40px;
  border-radius: 4px 20px 4px 20px;
  width: 90vw;

  & h4,
  h5,p {
    margin-bottom: 10px;
  }



  & > div:not(:first-child) {
    border-top: 2px solid var(--secondary);
    margin-top: 20px;
    padding-top: 20px;
  }
`

const MobileTableCard = styled.div`
  & .btn {
    margin-top: 5px;
    justify-self: flex-end;
  }
`

interface Props {
  data: any[]
  heading: string
}
export const PressReleaseTableMobile = ({ data, heading }: Props) => {
  return (
    <MobileTableContainer>
      {data
        .sort(
          (a: any, b: any) => b.key - a.key
        )
        .map((node, i) => (
          <MobileTableCard key={i}>
            <p>
              <small>{node.date}</small>
            </p>
            <h5>
              <i>{node.title}</i>
            </h5>
            <a className="btn" download href={`${node.download}`}>
              <svg
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                height="20"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                ></path>
              </svg>
              Download
            </a>
          </MobileTableCard>
        ))}
    </MobileTableContainer>
  )
}
