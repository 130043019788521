import React from "react"
import styled from "styled-components"

const Table = styled.table`
  background: white;
  max-width: 1800px;
  border: 4px solid var(--secondcolor);
  border-spacing: 0;
  border-radius: 4px 30px 4px 30px;
  margin-bottom: 50px;
  padding-left: 20px;
  min-height: 150px;
`
const TableBody = styled.tbody`
  padding: 5px;



  & tr {
    height: 5px;
  }
`
const TableHead = styled.thead`
  & tr {
    height: 50px;
  }
`
const TableHeading = styled.th`
  padding: 5px 10px 5px 0px;
  text-align: left;
  text-transform: capitalize;
`
const TableRow = styled.tr`
  & td::first-of-type {
    padding-left: 50px;
  }
`
const TableData = styled.td`
  font-size: 0.9rem;
  max-width: 20rem;
  padding-bottom: 20px;
  vertical-align: center;
  height: 50px;
`

interface Props {
  data: any[]
  heading: string
}

export const PressReleaseTable = ({ data }: Props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeading>Date</TableHeading>
          <TableHeading>Title</TableHeading>
          <TableHeading>Download</TableHeading>
        </TableRow>
      </TableHead>
      <TableBody>
        {data
          .sort(
            (a: any, b: any) => b.key - a.key
          )
          .map((edge, i) => (
            <TableRow key={i}>
              <TableData>{edge.date}</TableData>
              <TableData>{edge.title}</TableData>
              <TableData>
                <a
                  className="btn"
                  download
                  href={`${edge.download}`}
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    ></path>
                  </svg>
                  Download
                </a>
              </TableData>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}
